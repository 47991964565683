.rbc-calendar {
    height: fit-content !important;
    .rbc-month-view,
    .rbc-header {
        border: none;
        border-left: none !important;
    }
    .rbc-header {
        border-top-left-radius: 20px;
        margin: 5px 10px;
        padding: 8px 15px;
        background-color: #f2f4f7;
        color: #999;
        min-height: 40px;
    }
    .rbc-row-bg {
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }
}

.internal-meeting{
    .rbc-calendar {
        min-height: 700px !important;
    }
}

.dashboard-calendar-meeting{
  .rbc-calendar {
      min-height: 480px !important;
      max-height: 480px;
      overflow-y: auto;
  }
}

.calendarWrapper {
    &.smallCalendar {
      height: 300px;
    }
  
    &.bigCalendar {
      height: 700px;
    }
  
    & .rbc-toolbar {
      & .rbc-toolbar-label {
        font-weight: 700;
        text-transform: uppercase;
      }
  
      & .rbc-btn-group {
        & button {
          border-radius: 3px;
          border: none;
          font-size: 11px;
          transition: all 0.3s;
          padding: 8px 14px;
          background-color: #fafbfe;
  
          & svg {
            font-size: 15px;
            color: grey;
          }
  
          &.rbc-active {
            background-color: #f2f4f7;
            box-shadow: none;
          }
  
          &:hover {
            background-color: #e4e4e4;
          }
        }
      }
    }
  
    & .rbc-header {
      height: 40px;
      color: #999999;
      background: #f2f4f7;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    & .rbc-date-cell {
      padding-right: 0;
  
      & button {
        padding-left: 5px;
        text-align: left;
        font-size: 13px;
        font-weight: 500;
        width: 100%;
      }
    }
  
    & .rbc-row-segment {
      margin-top: 0px;
      // margin-bottom: -5px;
  
      & .rbc-event {
        height: auto;
        font-size: 12px;
  
        & .rbc-event-content {
          max-height: 60px;
          white-space: nowrap;
        }
      }
    }
  }