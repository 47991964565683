@import '../variables.scss';

// Custom Designed Accordion
.custom-accordion {
  margin: 16px 0px 0px 0px !important;
  .MuiAccordionSummary-root {
    background-color: $table-head-color;

  }
  .MuiAccordionSummary-root.Mui-expanded {
    background-color: $table-head-color;
    min-height: 48px !important  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px !important;
  }
}

.custom-accordion:first-child {
  margin: 0px !important;
}
