:root {
    --primaryColor: #022a40;
}

$primary-color:var(--primaryColor);
$primary-hover-color: var(--primaryColor);
$primary-hover-color-light: #e8f2f8;
$background-color: #f2f4f7;
$background-color-white: #ffffff;
$card-background-color: #ffffff;
$heading-color: #000;
$sub-heading-color: #999;
$color-additional: #999999;
$color-accent: #38a7ec;
$required-color: #e54643;
$text-color: #fff;
$table-head-color: #f1f8ff;

// input styles
$input-border-color:#afacac;