@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
@import "styles/common/customAccordion.module.scss";
@import "styles/common/invoice-template.module.scss";
@import "styles/common/payment-template.module.scss";
@import "styles/variables.scss";

$primary: $primary-color;
$bg-color: $background-color;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  background-color: #f3f5fa;
  // background-color: white;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

p {
  margin-bottom: 0 !important;
}
.Mui-selected:hover {
  color: white !important;
  span {
    color: white;
  }
}

button {
  background-color: $primary;
  &:hover {
    background-color: $primary !important;
    // border-radius: 10px 10px 0px 0px;
    color: white;
    svg {
      fill: white;
    }
    span {
      color: white;
    }
  }
}

.border-top-bottom {
  border: none !important;
  border-top: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
}
// .pointer {
//   cursor: pointer;
// }
div[disabled] {
  pointer-events: none;
  opacity: 0.2;
}
// .MuiTableCell-root{
//   white-space: nowrap;

// }
.modal-dialog--custom-max-width {
  max-width: 950px;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.noWrap {
  white-space: nowrap;
}
a {
  color: black;
  font-size: 16px;
  &:hover {
    color: $primary;
    text-decoration: none;
  }
}
.page-tips-body-wrapper {
  width: 400px;
  padding: 20px;
}
.truncate-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.custom-box {
  padding: auto;
  width: 100%;
  margin: 0 auto;
  border: 0.1px solid $primary;

  svg {
    width: 50px;
    height: 50px;
    color: $primary;
  }
}
.left-bordered-box {
  border: 1px solid #b4e6db;
  border-left: 3px solid #63ceb7;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 94%;
}
.line-item-table {
  .line-item-column {
    padding: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    vertical-align: middle;
    word-wrap: break-word;
  }

  .line-item-header {
    .line-item-column {
      padding: 8px 10px;
      color: #777;
      background-color: #f9f9f9;
    }
  }
}
.line-item-table {
  width: 95%;
  border: 1px solid #ccc;
}
.line-item-table .line-item-header .line-item-column {
  padding: 8px 10px;
  color: #777;
  background-color: #f9f9f9;
}
.preLineBreak {
  white-space: pre-line;
}

.ck.ck-content {
  min-height: 250px;
  max-height: 350px;
}
a {
  text-decoration: none;
}

.rbc-calendar {
  min-height: 302px !important;
}
.cursor {
  cursor: pointer !important;
  &:hover {
    color: #2485e8;
  }
}
.sidebar {
  position: fixed !important;
  background: white;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 0px);
  width: 250px;
}

.sidebar::-webkit-scrollbar,
.modal-scroll ::-webkit-scrollbar {
  width: 5px;
}

.sidebar::-webkit-scrollbar-track,
.modal-scroll ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebar::-webkit-scrollbar-thumb,
.modal-scroll ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.sidebar::-webkit-scrollbar-thumb:hover,
.modal-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.green-bubble {
  width: 6px;
  height: 6px;
  background-color: #28ba9b;
  display: inline-block;
  margin-right: 5px;
  border-radius: 50%;
  margin-bottom: 1px;
}

.required::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.grey-bg {
  background-color: #fbfafa !important;
}
// print
@media print {
  .no-print {
    visibility: hidden !important;
  }
  .do-print {
    visibility: visible !important;
  }
}
.Mui-disabled {
  background-color: #cccccc6a !important;
  input {
    background-color: transparent !important;
  }
}

.sidebar .MuiTypography-root {
  font-size: 0.9rem !important;
}

// Opening Balance form icons
.asset {
  svg {
    color: #fa3e81;
  }
}

.expense {
  svg {
    color: #ffad78;
  }
}

.liability {
  svg {
    color: rgba(215, 179, 66, 0.870588);
  }
}

.equity {
  svg {
    color: #2ddbcf;
  }
}

.income {
  svg {
    color: #fa3e81;
  }
}

.opening-balance-total {
  color: #777;
  background-color: #fdfae4;
  text-align: right;
  font-size: 12px;
}
.signature-pad {
  // width: 100%;
  height: 200px;
  border: 1px solid gray;
}
.black-box-inner {
  border-top: 2px dashed white;
  border-bottom: 2px dashed white;
}
.word-break {
  word-break: break-all;
}
.react-select__placeholder,
.MuiInputBase-input {
  font-size: 12px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.react-select__control--is-focused {
  border-color: none !important;
}

.MuiInputBase-root {
  padding-left: 5px !important;
}
.chart-of-account-detail-head {
  background-color: #fbfafa;
  padding: 20px;
  border-bottom: 2px dotted $primary;
}
.display-child-node {
  position: absolute;
  width: 18px;
  height: 43px;
  margin-left: -27px;
  border-bottom: 1px solid #adadad;
  border-left: 1px solid #adadad;
  transform: translateY(-48px);
}
.display-intermediate-node {
  position: absolute;
  width: 18px;
  height: 43px;
  border-left: 1px solid #adadad;
  transform: translateY(-28px);
}

.topbar-megamenu {
  li {
    &:hover {
      p {
        color: white;
      }
      svg {
        fill: white;
      }
    }

    svg {
      font-size: 12px;
      fill: #3b3b3b;
    }
    p {
      font-size: 13px;
      color: #3b3b3b;
    }
  }
}

@media (pointer: coarse) {
  /* Add your mobile-specific styles here */
  .isOnlyMobile {
    display: none !important;
  }
}

.MuiPagination-ul {
  & .css-u8pjqb-MuiButtonBase-root-MuiPaginationItem-root {
    background-color: #efefef;

    &:hover {
      background-color: #e0e0e0;
    }
  }
  & .css-u8pjqb-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: $primary;

    &:hover {
      background-color: $primary;
    }
  }
}

.sidebar .Mui-selected {
  span {
    color: white !important;
  }
  svg {
    fill: white !important;
  }
}
.calendar-label {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.paste-files-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: lighten(gray, 40%);
  margin-left: -10px;
  padding: 10px 25px;
  font-size: 15px;
}

.MuiTextField-root {
  background-color: white !important;
}

textarea {
  text-wrap: balance !important;
  border: 1px solid #7676764f;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px 10px;

  &:focus {
    outline: 2px solid $primary;
  }
}

.slick-arrow::after,
.slick-arrow::before {
  color: rgba(57, 57, 57, 0.601) !important;
}

.hover-black {
  color: black !important;
}

.annoucement-carousel {
  a {
    padding: 0px 20px !important;
  }
}
.menu-item {
  .MuiListItemIcon-root {
    min-width: 36px;
  }
}

.calendar-height {
  max-height: 310px;
  height: 100%;
  overflow-y: auto;
}

.invoice-receipt-main-container .pointer:before {
  border-left: 20.5px solid $primary;
}

.stepper-form {
  .Mui-disabled {
    background-color: transparent !important;
  }
}

.dashboard-tabs-panel .Mui-selected {
  background-color: $primary;
  border-top-left-radius: 20px;
  color: white !important;
  min-height: 30px !important;
  height: 40px !important;
}

table {
  // tbody {
  tr {
    &:nth-child(even) {
      background-color: $bg-color !important; /* Light gray background color */
    }
  }
  // }
}

.MuiButton-containedPrimary {
  &:hover {
    background-color: $primary !important;
  }
}

.svg-icon-btn {
  .svg-icon {
    fill: $primary;
  }
  &:hover .svg-icon {
    fill: white;
  }
}

.step-form-labels {
  .MuiStep-root:first-child {
    padding-left: 0px;
  }
  .MuiStepLabel-horizontal {
    flex-direction: column !important;
    gap: 5px;
  }
  .MuiStepLabel-label,
  .Mui-active {
    font-weight: 600 !important;
  }
  .Mui-disabled {
    background-color: transparent !important;
  }
}

.check {
  display: flex;
  margin-bottom: -60px;
}
.paidArrow {
  margin-bottom: 30px;
  display: flex;
  font-weight: bold;
  background-color: rgb(35, 172, 14);
  float: right;
  min-width: 55px;
  height: 35px;
  position: relative;
  padding: 0px 22px;
  justify-content: center;
  align-items: center;
  color: white;
  text-transform: capitalize;
}

.paidArrow:before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid rgb(35, 172, 14);
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
}

.css-1h9kpt3-MuiTypography-root {
  font-weight: 400 !important;
}

.profile-img-change {
  position: relative;
  cursor: pointer;
}

.profile-img-change:hover::after {
  content: ""; /* Required for the ::after pseudo-element */
  display: block; /* Ensures the pseudo-element is treated as a block element */
  position: absolute; /* Position it relative to the .profile-img-change element */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  top: 0;
  left: 0;
  min-height: 150px;
  min-width: 150px;
  background-image: url("./assets/img/profile-image-hover.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1; /* Ensure it appears above other content */
}

.dashboard-tabs-panel .dashboard-tab {
  &:hover {
    min-height: 30px !important;
    height: 40px !important;
    border-top-left-radius: 20px;
    color: white !important;
  }
}

.MuiPickersDay-dayWithMargin.Mui-selected {
  background-color: $primary !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  left: 236px;
  width: 100%;
  z-index: 1000; /* Adjust the z-index if needed to ensure it stays above other content */
  background: #f8f9fa; /* Ensure the background matches the rest of the UI */
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.fixed-header {
  position: relative;
  width: 100%;
  background-color: #f8f9fa;
  padding: 1rem;
  z-index: 1000;
  transition: top 0.3s;
}

.fixed-header.is-fixed {
  position: fixed;
  top: 0;
  width: calc(100% - 248px) !important;
  left: 235px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: add shadow for better visual separation */
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-group {
  display: flex;
  gap: 0.5rem;
}

.col-lg-2 {
  flex: 0 0 auto;
}

.col-lg-5 {
  flex: 1;
}

.detail-page-items-cells {
  td {
    background-color: white;
  }
}

.react-select__single-value{
  font-size: 12px;
}